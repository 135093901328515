.loader {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: block;
  &__img-wrapper {
    position: fixed;
    top: 40%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__img {
    display: inline-block;
    width: 160px;
    height: 160px;
    position: relative;
    background: #fff;
    box-sizing: border-box;
    animation: circleAnimation 1s linear infinite;
  }
}

@keyframes circleAnimation {
  0% {
    transform: perspective(200px) rotateY(0deg);
  }
  50% {
    transform: perspective(200px) rotateY(0deg);
  }
  100% {
    transform: perspective(200px) rotateY(-180deg);
  }
}
